@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');

body{
    background-color: #1D1C1A;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.hero{
    background-image: url('./media/heroheader.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.pisaniFont{
    font-family: "Style Script", cursive;
  font-weight: 400;
  font-style: normal;
}


.hero-logoHeader{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #CC7C27;
    border-bottom: 1px solid #CC7C27;
}

/* Rectangle 2 */
.ponudaPozadina{
    background: linear-gradient(180deg, rgba(29, 28, 26, 0.625) 0%, #000000d2 100%);
    opacity: 0.93;
}

.premaDole{
    transform: rotate(90deg)
}
