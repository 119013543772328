* {
  box-sizing: border-box;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Kolone zauzimaju 33% širine ekrana na većim ekranima */
  .column {
    flex: 33%;
    max-width: 33%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responzivnost za manje ekrane - dva stupca */
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responzivnost za mobilne uređaje - jedan stupac */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }